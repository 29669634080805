<template>
  <div class="px-9 my-5">
    <v-alert
      icon="mdi-bell-ring"
      color="lightGray"
    >
      <v-row align="center">
        <v-col class="grow">
          Push token status: <b>{{ device.push_token_status }}</b>
        </v-col>
        <v-col class="shrink">
          <s-btn
            x-small
            elevation="0"
            outlined
            color="primary"
            @click="emitEvent('wake')"
          >
            Wake device
          </s-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      icon="mdi-lock"
      color="lightGray"
    >
      <v-row align="center">
        <v-col class="grow">
          Send Low Prekeys Notifications
        </v-col>
        <v-col class="shrink">
          <s-btn
            x-small
            elevation="0"
            outlined
            color="primary"
            @click="emitEvent('prekeyslow')"
          >
            Send
          </s-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      icon="mdi-eye"
      color="lightGray"
    >
      <v-row align="center">
        <v-col class="grow">
          Send Reward Ad Notifications
        </v-col>
        <v-col class="shrink">
          <s-btn
            x-small
            elevation="0"
            outlined
            color="primary"
            @click="emitEvent('rewardad')"
          >
            Send
          </s-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-list-item-group class="mb-5">
      <v-list-item-content class="py-4 pl-0">
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Device Type</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ device.device_type || 'Unknown device' }}
          </s-text>
        </v-col>
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">App Version</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ device.app_version || 'Unknown' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Device ID
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ device.id || 'Unknown' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Device Telco
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ device.telco || 'Unknown' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Device Location / IP Address
          </s-text>
          <s-text size="lg" weight="bold">
            <span
              class="count-box bg-white pa-1 mr-2"
            >
              {{ deviceLocationInfo }}
            </span>
            <span
              class="count-box bg-white pa-1 mr-2"
            >
              {{ device.last_seen_ip }}
            </span>
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border bottom-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Last Seen
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ device.last_seen ? getHumanReadableDate(device.last_seen) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
    </v-list-item-group>
    <s-btn
      @click="goBack"
      type="button"
      color="primary"
      elevation="0"
    >
      OK
    </s-btn>
  </div>
</template>

<script>
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'ViewDeviceDetails',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceLocationInfo () {
      const state = this.device.device_location.address.state
      const country = this.device.device_location.address.country
      const getSeparator = () => {
        if (state && country) {
          return ', '
        }

        return ''
      }

      let _location = `${state}${getSeparator()}${country}`
      _location = _location === ''
        ? 'Unknown location'
        : _location

      return _location
    },
  },
  methods: {
    getHumanReadableDate,
    emitEvent (message) {
      this.$emit(message)
    },
    goBack () {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
